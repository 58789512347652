<template>
    <div :class="size">
        <v-card>
            <v-card-title>
                Google Hangout
            </v-card-title>
            <div class="wrapper">
                <v-list two-line class="pt-0">
                    <v-list-item-group>
                            <template v-for="(avatar, index) in avatars">
                                <v-list-item :key="`GDA-${index}`">
                                    <v-list-item-icon class="my-3">
                                        <v-avatar><img :src="avatar" /></v-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">Nom Prénom</v-list-item-title>
                                        <v-list-item-subtitle class="text--primary">Nouveau message</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-list-item-action-text>15h32</v-list-item-action-text>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                    </v-list-item-group>
                </v-list>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'GoogleHangout',
    props : {
        size : {
            type : String,
            default : 'md'
        }
    },
    data() {
        return {
            avatars : [
                'https://avataaars.io/?avatarStyle=Circle&topType=LongHairNotTooLong&accessoriesType=Kurt&hatColor=PastelBlue&hairColor=BlondeGolden&facialHairType=BeardLight&facialHairColor=Auburn&clotheType=Hoodie&clotheColor=PastelGreen&eyeType=WinkWacky&eyebrowType=Angry&mouthType=Disbelief&skinColor=Pale',
                'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShaggyMullet&accessoriesType=Wayfarers&hairColor=Red&facialHairType=MoustacheFancy&facialHairColor=Auburn&clotheType=BlazerSweater&clotheColor=White&eyeType=Happy&eyebrowType=UpDown&mouthType=Serious&skinColor=Light',
                'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight2&accessoriesType=Blank&hairColor=SilverGray&facialHairType=BeardLight&facialHairColor=Brown&clotheType=BlazerShirt&eyeType=Default&eyebrowType=FlatNatural&mouthType=Vomit&skinColor=Black',
                'https://avataaars.io/?avatarStyle=Circle&topType=LongHairCurly&accessoriesType=Round&hairColor=BlondeGolden&facialHairType=BeardMedium&facialHairColor=Blonde&clotheType=GraphicShirt&clotheColor=PastelYellow&graphicType=Pizza&eyeType=Close&eyebrowType=UpDown&mouthType=Serious&skinColor=Light',
                'https://avataaars.io/?avatarStyle=Circle&topType=WinterHat4&accessoriesType=Round&hatColor=Blue03&hairColor=Black&facialHairType=BeardMedium&facialHairColor=Black&clotheType=ShirtCrewNeck&clotheColor=Gray01&graphicType=Bear&eyeType=Happy&eyebrowType=DefaultNatural&mouthType=Vomit&skinColor=Light',
                'https://avataaars.io/?avatarStyle=Circle&topType=LongHairShavedSides&accessoriesType=Sunglasses&hatColor=PastelYellow&facialHairType=BeardLight&facialHairColor=Brown&clotheType=BlazerShirt&clotheColor=Blue01&eyeType=Squint&eyebrowType=SadConcernedNatural&mouthType=Grimace&skinColor=Tanned',
                'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShaggyMullet&accessoriesType=Blank&hairColor=Blonde&facialHairType=MoustacheFancy&facialHairColor=Brown&clotheType=BlazerShirt&eyeType=Hearts&eyebrowType=Default&mouthType=Twinkle&skinColor=Yellow',
                'https://avataaars.io/?avatarStyle=Circle&topType=Turban&accessoriesType=Wayfarers&hatColor=Blue01&hairColor=Auburn&facialHairType=Blank&facialHairColor=Red&clotheType=CollarSweater&clotheColor=Heather&eyeType=Hearts&eyebrowType=SadConcerned&mouthType=Sad&skinColor=Black',
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 3;   
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;
    }
    @media screen and (min-width: 1200px) {
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
    }
}
</style>